import { Col, Row } from 'react-bootstrap';
import IconLinkedIn from '../Assets/Img/Icons/Icon-LinkedIn.svg'
import IconArtStation from '../Assets/Img/Icons/Icon-ArtStation.svg'
import IconInstagram from '../Assets/Img/Icons/Icon-Instagram.svg'
import IconVimeo from '../Assets/Img/Icons/Icon-Vimeo.svg'
import IconEmail from '../Assets/Img/Icons/Icon-Email.svg'

export const Footer = () => {
    return (
        <section className="footer">
            <div>
                <footer >
                    <div className="social-icon">
                        <a href='https://www.instagram.com/thenatywebb/'><img src={IconInstagram} alt='' /></a>
                        <a href='https://vimeo.com/user51809261'><img src={IconVimeo} alt='' /></a>
                        <a href='https://www.linkedin.com/in/nataly-webb-137594147/'><img src={IconLinkedIn} alt='' /></a>
                        <a href='https://www.artstation.com/natywebb'><img src={IconArtStation} alt='' /></a>
                    </div>
                </footer >
            </div>
        </section>
    )

}