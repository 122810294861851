import { LazyLoadImage } from "react-lazy-load-image-component";
import { Nav, Navbar, Container, Row } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const logo = 'https://firebasestorage.googleapis.com/v0/b/portafolionatywebb.appspot.com/o/SectionsAux%2FImages%2Flogo.webp?alt=media&token=7591bf99-65ca-45de-8ec9-0622fea52ce6'

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

 

  return (
    <section>
      <section className='Nav-bar-general'>

        <div>
          <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
            <div>
              <Navbar.Toggle aria-controls="basic-navbar-nav"><span className='navbar-toggler-icon'></span></Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Navbar.Brand href="/"><LazyLoadImage src={logo} className='logo' alt="Logo" /></Navbar.Brand>
                  <Nav.Link href="login" className={activeLink === 'login' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>NDA</Nav.Link>
                  <Nav.Link href="#illustration" className={activeLink === 'Work' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('work')}>Illustrations</Nav.Link>
                  <Nav.Link href="#enviromentDesign" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>Environment Design</Nav.Link>
                  <Nav.Link href="#characterDesign" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}> Character Design</Nav.Link>
                  <Nav.Link href="#dWork" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}> 3D</Nav.Link>
                  <Nav.Link  href = "mailto: natywebb@gmail.com" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}> Contact Me</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
          <div className='navbar-header'>
            <Nav.Link href="/" className={activeLink === 'Work' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}><h1>NATY WEBB</h1></Nav.Link>
          </div>
        </div>
      </section>
    </section>
  );
}

/*

                  <a onClick={() => scrollToSection(illustration)}> Illustrations </a>
                  <a onClick={() => scrollToSection(enviromentDesign)}> Envitoment Design </a>
                  <a onClick={() => scrollToSection(characterDesign)}> Character Design </a>
                  <a onClick={() => scrollToSection(dWork)}> 3D </a>


 const illustration = useRef(null);
  const enviromentDesign = useRef(null);
  const characterDesign = useRef(null);
  const dWork = useRef(null);

  const scrollToSection = (elementRef) => {
      window.scrollTo({
          top: elementRef.current.offsetTop,
          behavior: 'smooth'
      });
  }

*/