import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './Components/NavBar';
import { Footer } from './Components/Footer'
import { useContext } from 'react';
import { AuthContext } from './Components/AuthContext';

const LazyBanner = React.lazy(() => import('./Components/Banner'));
const LazyNDA = React.lazy(() => import('./Components/NDA'));
const LazyNotFound = React.lazy(() => import('./Components/NotFound'));
const LazyLoginPage = React.lazy(() => import('./Components/Login'));

function App() {

  const {currentUser} = useContext(AuthContext)
  const RequireAuth = ({children}) => {
    return currentUser ? (children) : <Navigate to="/login" />
  }

  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Routes>
            <Route exact path='/' element={<React.Suspense fallback='Loading...'><LazyBanner /></React.Suspense>} />
            <Route exact path='/Work' element={<React.Suspense fallback='Loading...'><LazyBanner /></React.Suspense>} />
            <Route exact path='/login' element={<React.Suspense fallback='Loading...'><LazyLoginPage /></React.Suspense>} />
            <Route exact path='/NDA' element={<RequireAuth> <React.Suspense fallback='Loading...'><LazyNDA /></React.Suspense> </RequireAuth>} />
            <Route exact path='/*' element={<React.Suspense fallback='Loading...'><LazyNotFound /></React.Suspense>} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}
export default App;